import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './cadastro.css';

const CadastroContaReceberPage = () => {
  const [formData, setFormData] = useState({
    descricao: '',
    valor: '',
    data_vencimento: '',
    recebido: false // Alteração de 'pago' para 'recebido'
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/contasReceber', formData);
      alert('Conta a Receber criada com sucesso');
      history.push('/contasReceber');
    } catch (error) {
      console.error('Erro ao criar conta a receber:', error);
      alert('Erro ao criar conta a receber. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastro de Conta a Receber</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            name="valor"
            value={formData.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Vencimento:</label>
          <input
            type="date"
            name="data_vencimento"
            value={formData.data_vencimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Recebido:</label> {/* Alteração de 'Pago' para 'Recebido' */}
          <input
            type="checkbox"
            name="recebido" // Mantenha o nome do campo como 'pago'
            checked={formData.recebido} // Alteração de 'pago' para 'recebido'
            onChange={(e) => setFormData({ ...formData, recebido: e.target.checked })} // Alteração de 'pago' para 'recebido'
          />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
      <button onClick={() => history.push('/contasReceber')}>Voltar</button>
    </div>
  );
};

export default CadastroContaReceberPage;
