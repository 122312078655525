import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './cadastro.css';
import { useHistory } from 'react-router-dom';

const CadastroMensalidadePage = () => {
  const [formData, setFormData] = useState({
    AdeptoID: '',
    MesReferencia: '',
    Valor: '',
    DataPagamento: '',
    Pago: false
  });
  const history = useHistory();

  const [adeptos, setAdeptos] = useState([]);
  const [mesesReferencia, setMesesReferencia] = useState([]);

  useEffect(() => {
    const fetchAdeptos = async () => {
      try {
        const response = await axios.get('http://localhost:3000/adeptos');
        setAdeptos(response.data);
      } catch (error) {
        console.error('Erro ao buscar adeptos:', error);
      }
    };

    const fetchMesesReferencia = () => {
      const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
      setMesesReferencia(meses);
    };

    fetchAdeptos();
    fetchMesesReferencia();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/mensalidades', formData);
      alert('Mensalidade criada com sucesso');
    } catch (error) {
      console.error('Erro ao criar mensalidade:', error);
      alert('Erro ao criar mensalidade. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastro de Mensalidade</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Adepto:</label>
          <select
            name="AdeptoID"
            value={formData.AdeptoID}
            onChange={handleChange}
            required
          >
            <option value="">Selecione um adepto</option>
            {adeptos.map((adepto) => (
              <option key={adepto.AdeptoID} value={adepto.AdeptoID}>{adepto.NomeCompleto}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Mês de Referência:</label>
          <select
            name="MesReferencia"
            value={formData.MesReferencia}
            onChange={handleChange}
            required
          >
            <option value="">Selecione o mês de referência</option>
            {mesesReferencia.map((mes, index) => (
              <option key={index} value={mes}>{mes}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            name="Valor"
            value={formData.Valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Pagamento:</label>
          <input
            type="date"
            name="DataPagamento"
            value={formData.DataPagamento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Pago:</label>
          <input
            type="checkbox"
            name="Pago"
            checked={formData.Pago}
            onChange={(e) => setFormData({ ...formData, Pago: e.target.checked })}
          />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
      <button onClick={() => history.push('/mensalidades')}>Voltar</button>
    </div>
  );
};

export default CadastroMensalidadePage;
