import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './inicial.css';

const AssociarAdeptoPage = () => {
  const [adeptos, setAdeptos] = useState([]);
  const [obrigacoes, setObrigacoes] = useState([]);
  const [associacoes, setAssociacoes] = useState([]);
  const [formData, setFormData] = useState({
    adeptoId: '',
    obrigacaoId: '',
    data: '',
    padrinho: '',
    observacao: ''
  });
  const [selectedAdepto, setSelectedAdepto] = useState('');
  const [editId, setEditId] = useState(null); 
  
  const history = useHistory();

  useEffect(() => {
    fetchAdeptos();
    fetchObrigacoes();
  }, []);

  useEffect(() => {
    if (selectedAdepto) {
      fetchAssociacoes(selectedAdepto);
    } else {
      setAssociacoes([]);
      clearFormData();
    }
  }, [selectedAdepto]);

  const clearFormData = () => {
    setFormData({
      adeptoId: '',
      obrigacaoId: '',
      data: '',
      padrinho: '',
      observacao: ''
    });
  };

  const fetchAdeptos = async () => {
    try {
      const response = await axios.get('http://localhost:3000/adeptos');
      setAdeptos(response.data);
    } catch (error) {
      console.error('Erro ao buscar adeptos:', error);
    }
  };

  const fetchObrigacoes = async () => {
    try {
      const response = await axios.get('http://localhost:3000/obrigacoes');
      setObrigacoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar obrigações:', error);
    }
  };

  const fetchAssociacoes = async (adeptoId) => {
    try {
      const response = await axios.get(`http://localhost:3000/associacoes/${adeptoId}`);
      setAssociacoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar associações:', error);
    }
  };

  const handleAdeptoChange = (e) => {
    const adeptoId = e.target.value;
    setSelectedAdepto(adeptoId);
    const selected = adeptos.find(adepto => adepto.AdeptoID === parseInt(adeptoId));
    setFormData({
      ...formData,
      adeptoId,
      padrinho: selected ? selected.Padrinho : '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await axios.put(`http://localhost:3000/associacoes/${editId}`, formData);
        alert('Associação atualizada com sucesso');
        setEditId(null); 
      } else {
        await axios.post('http://localhost:3000/associacoes', formData);
        alert('Associação criada com sucesso');
      }
      clearFormData();
    } catch (error) {
      console.error('Erro ao salvar associação:', error);
      alert('Erro ao salvar associação. Tente novamente.');
    }
  };

  const handleEdit = (id) => {
    const associacao = associacoes.find(assoc => assoc.AssociacaoID === id);
    if (associacao) {
      setFormData({
        adeptoId: associacao.AdeptoID,
        obrigacaoId: associacao.ObrigacaoID,
        data: associacao.Data.split('T')[0],
        padrinho: associacao.Padrinho,
        observacao: associacao.Observacao
      });
      setEditId(id); 
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/associacoes/${id}`);
      alert('Associação deletada com sucesso');
      setAssociacoes(associacoes.filter(assoc => assoc.AssociacaoID !== id));
    } catch (error) {
      console.error('Erro ao deletar associação:', error);
      alert('Erro ao deletar associação. Tente novamente.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <h2>Associar Adepto a Obrigação</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Adepto:</label>
          <select name="adeptoId" value={formData.adeptoId} onChange={handleAdeptoChange} required>
            <option value="">Selecione um adepto</option>
            {adeptos.map(adepto => (
              <option key={adepto.AdeptoID} value={adepto.AdeptoID}>
                {adepto.NomeCompleto}
              </option>
            ))}
          </select>
        </div>
        {selectedAdepto && (
          <div>
            <label>Obrigação:</label>
            <select name="obrigacaoId" value={formData.obrigacaoId} onChange={handleChange} required>
              <option value="">Selecione uma obrigação</option>
              {obrigacoes.map(obrigacao => (
                <option key={obrigacao.id} value={obrigacao.id}>
                  {obrigacao.nome}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label>Data:</label>
          <input
            type="date"
            name="data"
            value={formData.data}
            onChange={handleChange}
            required
          />
        </div>
        {selectedAdepto && (
          <div>
            <label>Padrinho:</label>
            <input
            type="text"
            name="padrinho"
            value={formData.padrinho}
            onChange={handleChange}
            readOnly
          />
        </div>
        )}
        {selectedAdepto && (
        <div>
          <label>Observação:</label>
          <textarea
            name="observacao"
            value={formData.observacao}
            onChange={handleChange}
          />
        </div>
        )}
        <button type="submit">{editId ? 'Atualizar' : 'Associar'}</button>
      </form>

      <h3>Buscar Associações por Adepto</h3>
      <div>
        <label>Nome do Adepto:</label>
        <select onChange={handleAdeptoChange} value={selectedAdepto}>
          <option value="">Selecione um adepto</option>
          {adeptos.map(adepto => (
            <option key={adepto.AdeptoID} value={adepto.AdeptoID}>
              {adepto.NomeCompleto}
            </option>
          ))}
        </select>
      </div>

      {selectedAdepto && (
        <div>
          <h3>Associações de {adeptos.find(a => a.AdeptoID === parseInt(selectedAdepto))?.NomeCompleto}</h3>
          <table>
            <thead>
              <tr>
                <th>Obrigação</th>
                <th>Data</th>
                <th>Padrinho</th>
                <th>Observação</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {associacoes.map(associacao => (
                <tr key={associacao.AssociacaoID}>
                  <td>{obrigacoes.find(o => o.id === associacao.ObrigacaoID)?.nome}</td>
                  <td>{formatDate(associacao.Data)}</td>
                  <td>{associacao.Padrinho}</td>
                  <td>{associacao.Observacao}</td>
                  <td>
                    <button onClick={() => handleEdit(associacao.AssociacaoID)}>Editar</button>
                    <button onClick={() => handleDelete(associacao.AssociacaoID)}>Excluir</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default AssociarAdeptoPage;
