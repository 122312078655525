import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './inicial.css'

const AdeptosPage = () => {
  const [adeptos, setAdeptos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchAdeptos = async () => {
      try {
        const response = await axios.get('http://localhost:3000/adeptos');
        setAdeptos(response.data);
      } catch (error) {
        console.error('Erro ao buscar adeptos:', error);
      }
    };
    fetchAdeptos();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/adeptos/${id}`);
      setAdeptos(adeptos.filter(adepto => adepto.AdeptoID !== id));
      alert('Adepto deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar adepto:', error);
      alert('Erro ao deletar adepto. Tente novamente.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString(); // Pegar os últimos dois dígitos do ano
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <h2>Gestão de Adeptos</h2>
      
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data de Nascimento</th>
            <th>Orixá de Cabeça</th>
            <th>Orixá Ajunto</th>
            <th>Orixá de Passagem</th>
            <th>Data de Iniciação</th>
            <th>Padrinho</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {adeptos.map(adepto => (
            <tr key={adepto.AdeptoID}>
              <td>{adepto.NomeCompleto}</td>
              <td>{formatDate(adepto.DataNascimento)}</td>
              <td>{adepto.OrixaDeCabeca}</td>
              <td>{adepto.OrixaAjunto}</td>
              <td>{adepto.OrixaDePassagem}</td>
              <td>{formatDate(adepto.DataIniciacao)}</td>
              <td>{adepto.Padrinho}</td>
              <td>
                <button onClick={() => history.push(`/editarAdepto/${adepto.AdeptoID}`)}>Editar</button>
                <button onClick={() => handleDelete(adepto.AdeptoID)}>Deletar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => history.push('/cadastrarAdepto')}>Cadastrar Novo Adepto</button>
      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default AdeptosPage;