import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import './editar.css';

const EditarContaReceberPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    descricao: '',
    valor: '',
    data_vencimento: '',
    pago: false
  });
  const history = useHistory();

  useEffect(() => {
    const fetchContaReceber = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/contasReceber/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Erro ao buscar conta a receber:', error);
        alert('Erro ao buscar conta a receber. Tente novamente.');
      }
    };

    fetchContaReceber();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      pago: e.target.checked
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:3000/contasReceber/${id}`, formData);
      alert('Conta a Receber atualizada com sucesso');
      history.push('/contasReceber');
    } catch (error) {
      console.error('Erro ao atualizar conta a receber:', error);
      alert('Erro ao atualizar conta a receber. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Editar Conta a Receber</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            step="0.01"
            name="valor"
            value={formData.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Vencimento:</label>
          <input
            type="date"
            name="data_vencimento"
            value={formData.data_vencimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              name="pago"
              checked={formData.pago}
              onChange={handleCheckboxChange}
            />
            Pago
          </label>
        </div>
        <button type="submit">Salvar</button>
      </form>
      <button onClick={() => history.push('/contasReceber')}>Voltar</button>
    </div>
  );
};

export default EditarContaReceberPage;
