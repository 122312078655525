import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './inicial.css';

const EstoquePage = () => {
  const [materiais, setMateriais] = useState([]);
  const [formData, setFormData] = useState({
    nome_material: '',
    data_ultima_entrada: '',
    quantidade: '',
    observacao: ''
  });
  const [editId, setEditId] = useState(null); // Estado para armazenar o ID do material que está sendo editado
  const history = useHistory();

  useEffect(() => {
    // Carregar materiais de estoque ao carregar a página
    fetchMateriais();
  }, []);

  const fetchMateriais = async () => {
    try {
      const response = await axios.get('http://localhost:3000/estoque');
      setMateriais(response.data);
    } catch (error) {
      console.error('Erro ao buscar materiais de estoque:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adjustedFormData = {
      ...formData,
      data_ultima_entrada: new Date(formData.data_ultima_entrada).toISOString().split('T')[0] // Ajustar a data para o formato correto
    };
    if (editId) {
      // Se estiver editando, fazer a solicitação PUT para atualizar o material
      try {
        await axios.put(`http://localhost:3000/estoque/${editId}`, adjustedFormData);
        alert('Material de estoque atualizado com sucesso');
        setEditId(null); // Resetar o estado de edição após a atualização
      } catch (error) {
        console.error('Erro ao atualizar material de estoque:', error);
        alert('Erro ao atualizar material de estoque. Tente novamente.');
      }
    } else {
      // Se não estiver editando, fazer a solicitação POST para criar um novo material
      try {
        await axios.post('http://localhost:3000/estoque', adjustedFormData);
        alert('Material de estoque criado com sucesso');
      } catch (error) {
        console.error('Erro ao criar material de estoque:', error);
        alert('Erro ao criar material de estoque. Tente novamente.');
      }
    }
    // Limpar os campos do formulário após o cadastro ou atualização
    setFormData({
      nome_material: '',
      data_ultima_entrada: '',
      quantidade: '',
      observacao: ''
    });
    fetchMateriais(); // Atualizar a lista de materiais
  };

  const handleEdit = (id) => {
    const materialToEdit = materiais.find(material => material.EstoqueID === id);
    setFormData({
      nome_material: materialToEdit.NomeMaterial,
      data_ultima_entrada: materialToEdit.DataUltimaEntrada.split('T')[0], // Ajustar o formato da data
      quantidade: materialToEdit.Quantidade,
      observacao: materialToEdit.Observacao
    });
    setEditId(id); // Definir o ID do material que está sendo editado
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/estoque/${id}`);
      alert('Material de estoque excluído com sucesso');
      fetchMateriais(); // Atualizar a lista de materiais após a exclusão
    } catch (error) {
      console.error('Erro ao excluir material de estoque:', error);
      alert('Erro ao excluir material de estoque. Tente novamente.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <h2>Estoque</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome do Material:</label>
          <input
            type="text"
            name="nome_material"
            value={formData.nome_material}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data da Última Entrada:</label>
          <input
            type="date"
            name="data_ultima_entrada"
            value={formData.data_ultima_entrada}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Quantidade:</label>
          <input
            type="number"
            name="quantidade"
            value={formData.quantidade}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Observação:</label>
          <textarea
            name="observacao"
            value={formData.observacao}
            onChange={handleChange}
          />
        </div>
        <button type="submit">{editId ? 'Atualizar' : 'Cadastrar'}</button>
      </form>
      <h3>Lista de Materiais de Estoque</h3>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data da Última Entrada</th>
            <th>Quantidade</th>
            <th>Observação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {materiais.map(material => (
            <tr key={material.EstoqueID}>
              <td>{material.NomeMaterial}</td>
              <td>{formatDate(material.DataUltimaEntrada)}</td>
              <td>{material.Quantidade}</td>
              <td>{material.Observacao}</td>
              <td>
                <button onClick={() => handleEdit(material.EstoqueID)}>Editar</button>
                <button onClick={() => handleDelete(material.EstoqueID)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default EstoquePage;
