import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './editar.css';

const EditarMensalidadePage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    adeptoID: '',
    mes_referencia: '',
    valor: '',
    data_pagamento: '',
    pago: false
  });

  useEffect(() => {
    async function fetchMensalidade() {
      try {
        const response = await axios.get(`http://localhost:3000/mensalidades/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Erro ao buscar mensalidade:', error);
      }
    }

    fetchMensalidade();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:3000/mensalidades/${id}`, formData);
      alert('Mensalidade atualizada com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar mensalidade:', error);
      alert('Erro ao atualizar mensalidade. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Editar Mensalidade</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Adepto ID:</label>
          <input
            type="text"
            name="adeptoID"
            value={formData.adeptoID}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Mês de Referência:</label>
          <input
            type="text"
            name="mes_referencia"
            value={formData.mes_referencia}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            name="valor"
            value={formData.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Pagamento:</label>
          <input
            type="date"
            name="data_pagamento"
            value={formData.data_pagamento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Pago:</label>
          <input
            type="checkbox"
            name="pago"
            checked={formData.pago}
            onChange={(e) => setFormData({ ...formData, pago: e.target.checked })}
          />
        </div>
        <button type="submit">Salvar Alterações</button>
      </form>
    </div>
  );
};

export default EditarMensalidadePage;
