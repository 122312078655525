import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './inicial.css'

const AgendaPage = () => {
  const [eventos, setEventos] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await axios.get('http://localhost:3000/agenda');
        setEventos(response.data);
      } catch (error) {
        console.error('Erro ao buscar eventos:', error);
      }
    };
    fetchEventos();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/agenda/${id}`);
      setEventos(eventos.filter(evento => evento.EventoID !== id));
    } catch (error) {
      console.error('Erro ao deletar evento:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <div>
      <h2>Agenda de Eventos</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', borderBottom: '1px solid black', paddingBottom: '10px' }}>
          <div style={{ flex: 4, textAlign: 'center', padding: '5px' }}>Título</div>
          <div style={{ flex: 4, textAlign: 'center', padding: '5px' }}>Descrição</div>
          <div style={{ flex: 2, textAlign: 'center', padding: '5px' }}>Data de Início</div>
          <div style={{ flex: 2, textAlign: 'center', padding: '5px' }}>Data de Fim</div>
          <div style={{ flex: 2, textAlign: 'center', padding: '5px' }}>Ações</div>
        </div>
        {eventos.map(evento => (
          <div key={evento.EventoID} style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid black', padding: '5px' }}>
            <div style={{ flex: 4, padding: '5px' }}>{evento.Titulo}</div>
            <div style={{ flex: 4, padding: '5px' }}>{evento.Descricao}</div>
            <div style={{ flex: 2, padding: '5px' }}>{formatDate(evento.DataHoraInicio)}</div>
            <div style={{ flex: 2, padding: '5px' }}>{' ' + formatDate(evento.DataHoraFim)}</div>
            <div style={{ flex: 2, display: 'flex', justifyContent: 'space-between', padding: '5px' }}>
              <button onClick={() => history.push(`/editarEvento/${evento.EventoID}`)}>Editar</button>
              <button onClick={() => handleDelete(evento.EventoID)}>Deletar</button>
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <button onClick={() => history.push('/cadastrarEvento')}>Adicionar Evento</button>
        <button onClick={() => history.push('/menu')}>Voltar</button>
      </div>
    </div>
  );
};

export default AgendaPage;
