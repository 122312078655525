import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './inicial.css'

const ContasPagarPage = () => {
  const [contasPagar, setContasPagar] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchContasPagar = async () => {
      try {
        const response = await axios.get('http://localhost:3000/contasPagar');
        setContasPagar(response.data);
      } catch (error) {
        console.error('Erro ao buscar contas a pagar:', error);
        alert('Erro ao buscar contas a pagar. Tente novamente.');
      }
    };

    fetchContasPagar();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/contasPagar/${id}`);
      setContasPagar(contasPagar.filter((conta) => conta.ContaPagarID !== id));
    } catch (error) {
      console.error('Erro ao deletar conta a pagar:', error);
      alert('Erro ao deletar conta a pagar. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Contas a Pagar</h2>
      
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Data de Vencimento</th>
            <th>Pago</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contasPagar.map((conta) => (
            <tr key={conta.ContaPagarID}>
              <td>{conta.ContaPagarID}</td>
              <td>{conta.Descricao}</td>
              <td>{conta.Valor}</td>
              <td>{new Date(conta.DataVencimento).toLocaleDateString()}</td>
              <td>{conta.Pago ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => history.push(`/editarContaPagar/${conta.ContaPagarID}`)}>Editar</button>
                <button onClick={() => handleDelete(conta.ContaPagarID)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => history.push('/cadastrarContaPagar')}>Cadastrar Nova Conta</button>
      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default ContasPagarPage;
