import React from 'react';
import { Link } from 'react-router-dom';

const MenuPage = () => {
  return (
    <div>
      {/* Menu superior */}
      <div style={styles.menu}>
        <ul style={styles.list}>
          <li style={styles.item}><Link to="/adeptos" style={styles.link}>Gestão de Adeptos</Link></li>
          <li style={styles.item}><Link to="/cadastroobrigacao" style={styles.link}>Cadastro de Obrigações</Link></li>
          <li style={styles.item}><Link to="/associaradeptopage" style={styles.link}>Associar Obrigação a Adepto</Link></li>
          <li style={styles.item}><Link to="/agenda" style={styles.link}>Agenda</Link></li>
          <li style={styles.item}><Link to="/ContasReceber" style={styles.link}>Contas a Receber</Link></li>
          <li style={styles.item}><Link to="/contasPagar" style={styles.link}>Contas a Pagar</Link></li>
          <li style={styles.item}><Link to="/mensalidades" style={styles.link}>Gestão de Mensalidades</Link></li>
          <li style={styles.item}><Link to="/estoque" style={styles.link}>Controle de Estoque</Link></li>
          {/* Adicione mais itens de menu conforme necessário */}
        </ul>
      </div>

      {/* Div com o nome do centro religioso e logo */}
      <div style={styles.centroReligioso}>
        <h1>Reino de Oxum e Xapanã</h1>
        <img src="/images/logo.png" alt="Logo do Centro Religioso" style={styles.logo} />
      </div>

      {/* Rodapé */}
      <footer style={styles.footer}>
        <p style={styles.footerLeft}>Desenvolvido por Thauan Salles de Xapanã Sapatá</p>
        <p style={styles.footerRight}>Versão 1.0</p>
      </footer>
    </div>
  );
};

const styles = {
  menu: {
    backgroundColor: '#007BFF',
    padding: '10px',
    textAlign: 'center',
    maxWidth: '850px', // Definir largura máxima do menu
    margin: '0 auto', // Centralizar o menu na página
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  item: {
    display: 'inline-block',
    marginLeft: '10px',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    fontSize: '18px',
    fontWeight: 'bold',
    transition: 'opacity 0.3s',
  },
  centroReligioso: {
    textAlign: 'center',
    marginTop: '20px',
  },
  logo: {
    width: '300px', // Ajuste o tamanho conforme necessário
    height: 'auto',
    marginTop: '10px',
  },
  footer: {
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#000',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerLeft: {
    flexGrow: 1,
    textAlign: 'left',
  },
  footerRight: {
    textAlign: 'right',
  },
};

export default MenuPage;
