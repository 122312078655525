import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MenuPage from './components/MenuPage';
import AdeptosPage from './components/AdeptosPage';
import CadastroAdeptoPage from './components/CadastroAdeptoPage';
import EditarAdeptoPage from './components/EditarAdeptoPage';
import AgendaPage from './components/AgendaPage';
import CadastroEventoPage from './components/CadastroEventoPage';
import EditarEventoPage from './components/EditarEventoPage';
import ContasReceberPage from './components/ContasReceberPage';
import CadastroContaReceberPage from './components/CadastroContaReceberPage';
import EditarContaReceberPage from './components/EditarContaReceberPage';
import ContasPagarPage from './components/ContasPagarPage';
import CadastroContaPagarPage from './components/CadastroContaPagarPage';
import EditarContaPagarPage from './components/EditarContaPagarPage';
import MensalidadesPage from './components/MensalidadesPage';
import CadastroMensalidadePage from './components/CadastroMensalidadePage';
import EditarMensalidadePage from './components/EditarMensalidadePage';
import EstoquePage from './components/EstoquePage'; 
import CadastroObrigacaoPage from './components/CadastroObrigacaoPage';
import AssociarAdeptoPage from './components/AssociarAdeptoPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={MenuPage} />
        <Route path="/menu" component={MenuPage} />
        <Route path="/adeptos" component={AdeptosPage} />
        <Route path="/cadastrarAdepto" component={CadastroAdeptoPage} />
        <Route path="/editarAdepto/:id" component={EditarAdeptoPage} />
        <Route path="/agenda" component={AgendaPage} />
        <Route path="/cadastrarEvento" component={CadastroEventoPage} />
        <Route path="/editarEvento/:id" component={EditarEventoPage} />
        <Route path="/contasReceber" component={ContasReceberPage} />
        <Route path="/cadastrarContaReceber" component={CadastroContaReceberPage} />
        <Route path="/editarContaReceber/:id" component={EditarContaReceberPage} />
        <Route path="/contasPagar" component={ContasPagarPage} />
        <Route path="/cadastrarContaPagar" component={CadastroContaPagarPage} />
        <Route path="/editarContaPagar/:id" component={EditarContaPagarPage} />
        <Route path="/mensalidades" component={MensalidadesPage} />
        <Route path="/cadastrarMensalidade" component={CadastroMensalidadePage} />
        <Route path="/editarMensalidade/:id" component={EditarMensalidadePage} />
        <Route path="/estoque" component={EstoquePage} /> 
        <Route path="/cadastroobrigacao" component={CadastroObrigacaoPage} />
        <Route path="/associaradeptopage" component={AssociarAdeptoPage} />
      </Switch>
    </Router>
  );
}

export default App;
