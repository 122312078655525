import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import './editar.css';

const EditarContaPagarPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    descricao: '',
    valor: '',
    data_vencimento: '',
    pago: false
  });
  const history = useHistory();

  useEffect(() => {
    const fetchContaPagar = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/contasPagar/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Erro ao buscar conta a pagar:', error);
        alert('Erro ao buscar conta a pagar. Tente novamente.');
      }
    };

    fetchContaPagar();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:3000/contasPagar/${id}`, formData);
      alert('Conta a Pagar atualizada com sucesso');
      history.push('/contasPagar');
    } catch (error) {
      console.error('Erro ao atualizar conta a pagar:', error);
      alert('Erro ao atualizar conta a pagar. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Editar Conta a Pagar</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            name="valor"
            value={formData.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Vencimento:</label>
          <input
            type="date"
            name="data_vencimento"
            value={formData.data_vencimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Pago:</label>
          <input
            type="checkbox"
            name="pago"
            checked={formData.pago}
            onChange={(e) => setFormData({ ...formData, pago: e.target.checked })}
          />
        </div>
        <button type="submit">Atualizar</button>
      </form>
      <button onClick={() => history.push('/contasPagar')}>Voltar</button>
    </div>
  );
};

export default EditarContaPagarPage;
