import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './inicial.css'

const ContasReceberPage = () => {
  const [contasReceber, setContasReceber] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchContasReceber = async () => {
      try {
        const response = await axios.get('http://localhost:3000/contasReceber');
        setContasReceber(response.data);
      } catch (error) {
        console.error('Erro ao buscar contas a receber:', error);
        alert('Erro ao buscar contas a receber. Tente novamente.');
      }
    };

    fetchContasReceber();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/contasReceber/${id}`);
      setContasReceber(contasReceber.filter((conta) => conta.ContaReceberID !== id));
    } catch (error) {
      console.error('Erro ao deletar conta a receber:', error);
      alert('Erro ao deletar conta a receber. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Contas a Receber</h2>
      
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Data de Vencimento</th>
            <th>Pago</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {contasReceber.map((conta) => (
            <tr key={conta.ContaReceberID}>
              <td>{conta.ContaReceberID}</td>
              <td>{conta.Descricao}</td>
              <td>{conta.Valor}</td>
              <td>{new Date(conta.DataVencimento).toLocaleDateString()}</td>
              <td>{conta.Pago ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => history.push(`/editarContaReceber/${conta.ContaReceberID}`)}>Editar</button>
                <button onClick={() => handleDelete(conta.ContaReceberID)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => history.push('/cadastrarContaReceber')}>Cadastrar Nova Conta</button>
      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default ContasReceberPage;
