import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './cadastro.css';

const CadastroContaPagarPage = () => {
  const [formData, setFormData] = useState({
    descricao: '',
    valor: '',
    data_vencimento: '',
    pago: false
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/contasPagar', formData);
      alert('Conta a Pagar criada com sucesso');
      history.push('/contasPagar');
    } catch (error) {
      console.error('Erro ao criar conta a pagar:', error);
      alert('Erro ao criar conta a pagar. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastro de Conta a Pagar</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Valor:</label>
          <input
            type="number"
            name="valor"
            value={formData.valor}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Vencimento:</label>
          <input
            type="date"
            name="data_vencimento"
            value={formData.data_vencimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Pago:</label>
          <input
            type="checkbox"
            name="pago"
            checked={formData.pago}
            onChange={(e) => setFormData({ ...formData, pago: e.target.checked })}
          />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
      <button onClick={() => history.push('/contasPagar')}>Voltar</button>
    </div>
  );
};

export default CadastroContaPagarPage;
