import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './inicial.css';

const MensalidadesPage = () => {
  const [mensalidades, setMensalidades] = useState([]);
  const [error, setError] = useState(null);
  const [nomesAdeptos, setNomesAdeptos] = useState({});
  const history = useHistory();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value) => {
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  useEffect(() => {
    async function fetchMensalidades() {
      try {
        const response = await axios.get('http://localhost:3000/mensalidades');
        setMensalidades(response.data);
      } catch (error) {
        setError(error.message);
      }
    }
    fetchMensalidades();
  }, []);

  useEffect(() => {
    async function fetchNomesAdeptos() {
      const promises = mensalidades.map(async (mensalidade) => {
        try {
          const response = await axios.get(`http://localhost:3000/adeptos/${mensalidade.AdeptoID}`);
          return { id: mensalidade.MensalidadeID, nome: response.data.NomeCompleto };
        } catch (error) {
          console.error('Erro ao obter o nome do adepto:', error);
          return { id: mensalidade.MensalidadeID, nome: 'Nome do Adepto Não Encontrado' };
        }
      });
      const resolvedNomesAdeptos = await Promise.all(promises);
      const nomesAdeptosMap = resolvedNomesAdeptos.reduce((acc, curr) => {
        acc[curr.id] = curr.nome;
        return acc;
      }, {});
      setNomesAdeptos(nomesAdeptosMap);
    }
    fetchNomesAdeptos();
  }, [mensalidades]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:3000/mensalidades/${id}`);
      setMensalidades(mensalidades.filter(mensalidade => mensalidade.MensalidadeID !== id));
    } catch (error) {
      console.error('Erro ao deletar mensalidade:', error);
    }
  };

  const handleQuitar = async (id) => {
    try {
      await axios.put(`http://localhost:3000/mensalidades/${id}/quitar`);
      setMensalidades(mensalidades.map(mensalidade => {
        if (mensalidade.MensalidadeID === id) {
          return { ...mensalidade, Pago: true };
        }
        return mensalidade;
      }));
    } catch (error) {
      console.error('Erro ao quitar mensalidade:', error);
    }
  };

  return (
    <div>
      <h2>Mensalidades</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Adepto</th>
            <th>Mês de Referência</th>
            <th>Valor</th>
            <th>Data de Pagamento</th>
            <th>Pago</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {mensalidades.map((mensalidade) => (
            <tr key={mensalidade.MensalidadeID}>
              <td>{mensalidade.MensalidadeID}</td>
              <td>{nomesAdeptos[mensalidade.MensalidadeID]}</td>
              <td>{mensalidade.MesReferencia}</td>
              <td>{formatCurrency(mensalidade.Valor)}</td>
              <td>{formatDate(mensalidade.DataPagamento)}</td>
              <td>{mensalidade.Pago ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => handleDelete(mensalidade.MensalidadeID)}>Excluir</button>
                {!mensalidade.Pago && <button onClick={() => handleQuitar(mensalidade.MensalidadeID)}>Quitar</button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => history.push('/cadastrarMensalidade')}>Cadastrar Mensalidade</button>
      {error && <p>{error}</p>}
      <button onClick={() => history.push('/menu')}>Voltar</button>
    </div>
  );
};

export default MensalidadesPage;
