import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import './editar.css';

const EditarEventoPage = () => {
  const [formData, setFormData] = useState({
    titulo: '',
    descricao: '',
    data_hora_inicio: '',
    data_hora_fim: ''
  });
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchEvento = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/agenda/${id}`);
        const { Titulo, Descricao, DataHoraInicio, DataHoraFim } = response.data;
        setFormData({
          titulo: Titulo,
          descricao: Descricao,
          data_hora_inicio: DataHoraInicio,
          data_hora_fim: DataHoraFim
        });
      } catch (error) {
        console.error('Erro ao buscar evento:', error);
      }
    };
    fetchEvento();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:3000/agenda/${id}`, {
        titulo: formData.titulo,
        descricao: formData.descricao,
        data_hora_inicio: formData.data_hora_inicio,
        data_hora_fim: formData.data_hora_fim
      });
      alert('Evento atualizado com sucesso');
      history.push('/agenda');
    } catch (error) {
      console.error('Erro ao atualizar evento:', error);
      alert('Erro ao atualizar evento. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Editar Evento</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Título:</label>
          <input
            type="text"
            name="titulo"
            value={formData.titulo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Data e Hora de Início:</label>
          <input
            type="datetime-local"
            name="data_hora_inicio"
            value={formData.data_hora_inicio}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data e Hora de Fim:</label>
          <input
            type="datetime-local"
            name="data_hora_fim"
            value={formData.data_hora_fim}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Atualizar</button>
      </form>
      <button onClick={() => history.push('/agenda')}>Voltar</button>
    </div>
  );
};

export default EditarEventoPage;
