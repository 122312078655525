import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './cadastro.css';

const CadastroEventoPage = () => {
  const [formData, setFormData] = useState({
    titulo: '',
    descricao: '',
    data_hora_inicio: '',
    data_hora_fim: ''
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/agenda', {
        titulo: formData.titulo,
        descricao: formData.descricao,
        data_hora_inicio: formData.data_hora_inicio,
        data_hora_fim: formData.data_hora_fim
      });
      alert('Evento criado com sucesso');
      history.push('/agenda');
    } catch (error) {
      console.error('Erro ao criar evento:', error);
      alert('Erro ao criar evento. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastro de Evento</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Título:</label>
          <input
            type="text"
            name="titulo"
            value={formData.titulo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Data e Hora de Início:</label>
          <input
            type="datetime-local"
            name="data_hora_inicio"
            value={formData.data_hora_inicio}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data e Hora de Fim:</label>
          <input
            type="datetime-local"
            name="data_hora_fim"
            value={formData.data_hora_fim}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
      <button onClick={() => history.push('/agenda')}>Voltar</button>
    </div>
  );
};

export default CadastroEventoPage;
