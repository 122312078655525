import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './cadastro.css'; // Arquivo CSS para estilização

const CadastroObrigacaoPage = () => {
  const [nome, setNome] = useState('');
  const [bixos, setBixos] = useState('');
  const [ferramentas, setFerramentas] = useState('');
  const [ervas, setErvas] = useState('');
  const [obrigacoes, setObrigacoes] = useState([]);
  const history = useHistory();

  const fetchObrigacoes = async () => {
    try {
      const response = await axios.get('http://localhost:3000/obrigacoes');
      setObrigacoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar obrigações:', error);
    }
  };

  useEffect(() => { 
    fetchObrigacoes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/obrigacoes', {
        nome,
        bixos,
        ferramentas,
        ervas
      });
      alert('Obrigação cadastrada com sucesso!');
      // Limpar os campos após o cadastro
      setNome('');
      setBixos('');
      setFerramentas('');
      setErvas('');
      // Atualizar a lista de obrigações após o cadastro
      fetchObrigacoes();
    } catch (error) {
      console.error('Erro ao cadastrar obrigação:', error);
      alert('Erro ao cadastrar obrigação. Tente novamente.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir esta obrigação?')) {
      try {
        await axios.delete(`http://localhost:3000/obrigacoes/${id}`);
        alert('Obrigação excluída com sucesso!');
        // Atualizar a lista de obrigações após a exclusão
        fetchObrigacoes();
      } catch (error) {
        console.error('Erro ao excluir obrigação:', error);
        alert('Erro ao excluir obrigação. Tente novamente.');
      }
    }
  };

  return (
    <div className="cadastro-obrigacao-container">
      <h2>Cadastro de Obrigações Religiosas</h2>
      <form onSubmit={handleSubmit} className="cadastro-form">
        <div className="campo-form">
          <label>Nome:</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} maxLength={50} />
        </div>
        <div className="campo-form">
          <label>Bixos:</label>
          <textarea value={bixos} onChange={(e) => setBixos(e.target.value)} />
        </div>
        <div className="campo-form">
          <label>Itens necessários:</label>
          <textarea value={ferramentas} onChange={(e) => setFerramentas(e.target.value)} />
        </div>
        <div className="campo-form">
          <label>Ervas:</label>
          <textarea value={ervas} onChange={(e) => setErvas(e.target.value)} />
        </div>
        <button type="submit" className="botao-cadastrar">Cadastrar Obrigação</button>
      </form>

      <h2>Listagem de Obrigações Religiosas</h2>
      <div className="lista-obrigacoes">
        {obrigacoes.map(obrigacao => (
          <div key={obrigacao.id} className="obrigacao-item">
            <div className="info-obrigacao">
              <h3>{obrigacao.nome}</h3>
              <p><strong>Bixos:</strong> {obrigacao.bixos}</p>
              <p><strong>Itens necessários:</strong> {obrigacao.ferramentas}</p>
              <p><strong>Ervas:</strong> {obrigacao.ervas}</p>
            </div>
            <div className="botoes-acoes">
              <button onClick={() => handleDelete(obrigacao.id)}>Excluir</button>
            </div>
          </div>
        ))}
      </div>
      
      <button onClick={() => history.push('/menu')} className="botao-voltar">Voltar</button>
    </div>
  );
};

export default CadastroObrigacaoPage;
