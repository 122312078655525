import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import './editar.css';

const EditarAdeptoPage = () => {
  const [formData, setFormData] = useState({
    nome_completo: '',
    data_nascimento: '',
    endereco: '',
    orixa_de_cabeca: '',
    orixa_ajunto: '',
    orixa_de_passagem: '',
    data_iniciacao: '',
    padrinho: ''
  });
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchAdepto = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/adeptos/${id}`);
        const { NomeCompleto, DataNascimento, Endereco, OrixaDeCabeca, OrixaAjunto, OrixaDePassagem, DataIniciacao, Padrinho } = response.data;
        setFormData({
          nome_completo: NomeCompleto,
          data_nascimento: DataNascimento,
          endereco: Endereco,
          orixa_de_cabeca: OrixaDeCabeca,
          orixa_ajunto: OrixaAjunto,
          orixa_de_passagem: OrixaDePassagem,
          data_iniciacao: DataIniciacao,
          padrinho: Padrinho
        });
      } catch (error) {
        console.error('Erro ao buscar adepto:', error);
      }
    };
    fetchAdepto();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`http://localhost:3000/adeptos/${id}`, formData);
      alert('Adepto atualizado com sucesso');
      history.push('/adeptos');
    } catch (error) {
      console.error('Erro ao atualizar adepto:', error);
      alert('Erro ao atualizar adepto. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Editar Adepto</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome Completo:</label>
          <input
            type="text"
            name="nome_completo"
            value={formData.nome_completo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Nascimento:</label>
          <input
            type="date"
            name="data_nascimento"
            value={formData.data_nascimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Endereço:</label>
          <input
            type="text"
            name="endereco"
            value={formData.endereco}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá de Cabeça:</label>
          <input
            type="text"
            name="orixa_de_cabeca"
            value={formData.orixa_de_cabeca}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá Ajunto:</label>
          <input
            type="text"
            name="orixa_ajunto"
            value={formData.orixa_ajunto}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá de Passagem:</label>
          <input
            type="text"
            name="orixa_de_passagem"
            value={formData.orixa_de_passagem}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Data de Iniciação:</label>
          <input
            type="date"
            name="data_iniciacao"
            value={formData.data_iniciacao}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Padrinho:</label>
          <input
            type="text"
            name="padrinho"
            value={formData.padrinho}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Atualizar</button>
      </form>
      <button onClick={() => history.push('/adeptos')}>Voltar</button>
    </div>
  );
};

export default EditarAdeptoPage;
