import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './cadastro.css';

const CadastroAdeptoPage = () => {
  const [formData, setFormData] = useState({
    nome_completo: '',
    data_nascimento: '',
    endereco: '',
    orixa_de_cabeca: '',
    orixa_ajunto: '',
    orixa_de_passagem: '',
    data_iniciacao: '',
    padrinho: ''
  });

  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3000/adeptos', formData);
      alert('Adepto criado com sucesso');
      history.push('/adeptos');
    } catch (error) {
      console.error('Erro ao criar adepto:', error);
      alert('Erro ao criar adepto. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Cadastro de Adepto</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nome Completo:</label>
          <input
            type="text"
            name="nome_completo"
            value={formData.nome_completo}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Data de Nascimento:</label>
          <input
            type="date"
            name="data_nascimento"
            value={formData.data_nascimento}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Endereço:</label>
          <input
            type="text"
            name="endereco"
            value={formData.endereco}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá de Cabeça:</label>
          <input
            type="text"
            name="orixa_de_cabeca"
            value={formData.orixa_de_cabeca}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá Ajunto:</label>
          <input
            type="text"
            name="orixa_ajunto"
            value={formData.orixa_ajunto}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Orixá de Passagem:</label>
          <input
            type="text"
            name="orixa_de_passagem"
            value={formData.orixa_de_passagem}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Data de Iniciação:</label>
          <input
            type="date"
            name="data_iniciacao"
            value={formData.data_iniciacao}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Padrinho:</label>
          <input
            type="text"
            name="padrinho"
            value={formData.padrinho}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Cadastrar</button>
      </form>
      <button onClick={() => history.push('/adeptos')}>Voltar</button>
    </div>
  );
};

export default CadastroAdeptoPage;
